/* ----------------------------------------------------------------------------------
import読み込み
---------------------------------------------------------------------------------- */
import {
  contentAdjustFunc,
  resetStyleFunc,
  telModalFunc,
} from './common/_common';
import {
  hoverFunc,
  telLinkFunc,
  uaHoverFunc,
  hoverOtherFunc,
} from './common/_hoverFunc';
import {
  headerFunc,
  headerScrFunc
} from './common/_header';
import {
  footerFunc
} from './common/_footer';
import {
  resizeFuncCheck,
  resizeFunc
} from './common/_resizecheck';
import {
  heightLine,
  heightLineSetFunc,
  heightLineFunc,
} from './common/_heightLine';
import {
  pageLinkAnimationFunc,
  pageMoveAnkerFunc,
} from './common/_pageLinkAnimation';
import {
  ageConfFunc
} from './common/_age';
import {
  scrAboutFunc,
  scrAnimationFunc,
  navScrollFixed,
} from './common/_scrollAnime';
import {
  aboutQaFunc
} from './common/_buttonAction';
import {
  modalImgFunc
} from './common/_modal';
import {
  //動画なし
  //idxTopSliderFunc,
  idxModelSliderFunc,
  idxEventlSliderFunc,
} from './pages/_index';

import {
  idxTopSliderMovieFunc
} from './pages/_bxSliderIdx';

import {
  interviewGallery
} from './pages/_interviewSingle';
import {
  contactFunc
} from './pages/_contact';
import {
  worksIdxAosFunc
} from './lib/_aosSetting';
/* ----------------------------------------------------------------------------------
通常実行
---------------------------------------------------------------------------------- */
var indexTopLen, setTimer, setResponsibleCheck;
indexTopLen = $('#indexTop');
setTimer = false;
setResponsibleCheck = $('#responsibleCheck').width();
scrAnimationFunc();

/* ----------------------------------------------------------------------------------
window実行
---------------------------------------------------------------------------------- */
// ロード時
$(window).on('load', function () {
  contentAdjustFunc();
  headerFunc();
  pageLinkAnimationFunc();
  pageMoveAnkerFunc();
  headerScrFunc();
  footerFunc();
  navScrollFixed();
  //トップページ(トップスライドショー)
  if (indexTopLen.length) {
    //idxTopSliderFunc();
    //idxTopSliderMovieFunc();
    idxModelSliderFunc();
    idxEventlSliderFunc();
  }
  //インタビューシングルページスライドショー
  interviewGallery();
  //高さ調整
  heightLine();
  heightLineSetFunc();

  //18禁認証
  ageConfFunc();

  //AOS設定
  worksIdxAosFunc();

  //マインズのお仕事
  scrAboutFunc();
  aboutQaFunc();

  //お問い合わせ
  contactFunc();
});

// リサイズ時
/*
リサイズ後,200msで発火する設定
*/
$(window).on('resize', function () {
  if (setTimer !== false) {
    clearTimeout(setTimer);
    
  }
  setTimer = setTimeout(function () {
    //リサイズ終了後の処理
    contentAdjustFunc();
  }, 200);
  resetStyleFunc();
  heightLineSetFunc();
});

// スクロール時
$(window).on('scroll', function () {
  headerScrFunc();
});
/* ----------------------------------------------------------------------------------
ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
---------------------------------------------------------------------------------- */
var uaFlag = 0;
if (
  navigator.userAgent.indexOf('iPhone') > 0 ||
  navigator.userAgent.indexOf('iPod') > 0 ||
  navigator.userAgent.indexOf('iPad') > 0 ||
  navigator.userAgent.indexOf('Android') > 0 ||
  navigator.userAgent.indexOf('BlackBerry') > 0 ||
  navigator.userAgent.indexOf('PlayBook') > 0 ||
  navigator.userAgent.indexOf('Kindle') > 0 ||
  navigator.userAgent.indexOf('Silk') > 0 ||
  navigator.userAgent.indexOf('Windows Phone') > 0 ||
  navigator.userAgent.indexOf('Nokia') > 0
) {
  // スマホ・タブレット時の処理
  uaFlag = 1;
  uaHoverFunc();
} else {
  // PC時の処理
  uaFlag = 3;
  hoverFunc();
  telModalFunc();
  hoverOtherFunc();
  modalImgFunc();
}
/* ----------------------------------------------------------------------------------
IEだけの時
---------------------------------------------------------------------------------- */
var userAgent = window.navigator.userAgent.toLowerCase();
if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
  contentAdjustFunc();
  headerFunc();
  pageLinkAnimationFunc();
  pageMoveAnkerFunc();
  headerScrFunc();
  footerFunc();
  //18禁認証
  ageConfFunc();
  //お問い合わせ
  contactFunc();
  //AOS設定
  worksIdxAosFunc();
}