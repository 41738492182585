import bxSlider from 'bxslider/dist/jquery.bxslider.js';
import Cookies from 'js-cookie';
export function idxTopSliderMovieFunc(res) {
  var $video = $('.video').get(0);
  var $slider;

  $('.audio').on('click', function () {
    console.log('呼ばれた');
    $video.muted = false;
  });

  var firstVideo = $('.video').get(0);
  var clickFlag;
  $('.echoVolume').on('click', function () {
    alert('押されたよ');
  });


//  $(document).on("click", ".echoVolume", function () {
//    alert('押されたよ');
//    var thisVideo = $(this).prev().get(0);
//    var videoStatus = thisVideo.paused;
//    var thisVideoVolumeStatus = $(this).prev().find('video').prop('muted');
//    if (thisVideoVolumeStatus) {
//      //ミュート状態だったら
//      thisVideo.muted = false;
//    } else {
//      //ミュート状態ではなかったら
//      thisVideo.muted = true;
//    }
//  });

  function movieControlFunc() {
    console.log('こんにちは');
    //    console.log($video);
    //    if($video.muted){
    //      setTimeout(function () {
    //        $video.muted = false;
    //      }, 800);
    //    }else{
    //        console.log('解除しない');
    //    }
  }

  function initTopSwiper() {
    $slider = $('.bxslider').bxSlider({
      infiniteLoop: true,
      onSliderLoad: function () {
        $video.play();
        console.log('読んだ?');
        movieControlFunc();
      },
      onSlideAfter: function ($slideElement, oldIndex, newIndex) {
        if (newIndex == 0) {
          $video.play();
          movieControlFunc();
        } else {
          $video.pause();
          $video.currentTime = 0;
          $slider.startAuto();
        }
      }
    });

    $video.addEventListener("play", function () {
      $slider.stopAuto();
    });

    $video.addEventListener("ended", function () {
      $slider.goToNextSlide();
    });
  }

  if (res == 'ok') {
    console.log('実行');
    initTopSwiper();
  } else {
    console.log('何もしない');
  }
}