import Swiper from 'swiper';
import Cookies from 'js-cookie';
//トップページ　キービジュアルスライダー
export function idxTopSliderMovieFunc() {
  var ageDecision;
  let videos = document.getElementsByTagName("video");
  let videosSp = document.getElementsByTagName("videoSp");
  ageDecision = Cookies.get('age');
  var slideResizecheck = $('#responsibleCheck').width();
  
  console.log(slideResizecheck);
  
  var topSwiper, topOptions, realIndex, slideClass;
  let setFirstMove = document.getElementsByTagName("video");
  topSwiper = undefined;
  setFirstMove = true;
  topOptions = {
    loop: true,
    speed: 800,
    effect: 'slide',
    normalizeSlideIndex: true,
    paginationClickable: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.topNext',
      prevEl: '.topPrev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  };

  var firstVideo = $('.video').get(0);
  var clickFlag;
  clickFlag = true;
  $(document).on("click", ".echoVolume", function () {
    $(this).toggleClass('off');
    var thisVideo = $(this).prev().get(0);
    if (clickFlag) {
      clickFlag = false;
      thisVideo.muted = false;
    } else {
      clickFlag = true;
      thisVideo.muted = true;
    }
  });


  /*18禁認証*/
  if (ageDecision) {
    initTopSwiper();
  }
  //スワイプ実行処理
  function initTopSwiper() {
    topSwiper = new Swiper('#topSlide', topOptions);
    $('#topSlide').find('video').each(function (i) {
      $(this).addClass('number' + (i + 1));
    });

    $('#topSlide').find('video').each(function (i) {
      $(this).addClass('number' + (i + 1));
    });

    topSwiper.autoplay.stop();
    if(slideResizecheck == 1){
      console.log('pc');
      videos[0].currentTime = 0;
      videos[0].play();
    }else{
      console.log('sp');
      videos[1].currentTime = 0;
      videos[1].play();
    }
  }


  topSwiper.on('slideChangeTransitionEnd', function () {
    slideResizecheck = $('#responsibleCheck').width();
    if (topSwiper.realIndex == 0) {
      console.log(slideResizecheck);
      setTimeout(function () {
        topSwiper.autoplay.stop();
        if(slideResizecheck == 1){
          videos[0].currentTime = 0;
          videos[0].play();
          videos[2].currentTime = 0;
          videos[2].play();
        }else{
          videos[1].currentTime = 0;
          videos[1].play();
          videos[3].currentTime = 0;
          videos[3].play();
        }
        
      }, 800);
    } else if (topSwiper.realIndex != 0) {
      videos[0].currentTime = 0;
      videos[0].pause();
      videos[0].muted = true;
      videos[1].currentTime = 0;
      videos[1].pause();
      videos[1].muted = true;
      videos[2].currentTime = 0;
      videos[2].pause();
      videos[2].muted = true;
      videos[3].currentTime = 0;
      videos[3].pause();
      videos[3].muted = true;
      clickFlag = true;
    }
  });

  videos[0].addEventListener("ended", function () {
    topSwiper.slideNext();
    topSwiper.autoplay.start();
    $('.echoVolume').removeClass('off');
  }, false);
  videos[1].addEventListener("ended", function () {
    topSwiper.slideNext();
    topSwiper.autoplay.start();
    $('.echoVolume').removeClass('off');
  }, false);
  videos[2].addEventListener("ended", function () {
    topSwiper.slideNext();
    topSwiper.autoplay.start();
    $('.echoVolume').removeClass('off');
  }, false);
  videos[3].addEventListener("ended", function () {
    topSwiper.slideNext();
    topSwiper.autoplay.start();
    $('.echoVolume').removeClass('off');
  }, false);
}