import Swiper from 'swiper';
import {
  modalImgFunc
} from '../common/_modal';
//トップページ　キービジュアルスライダー
export function interviewGallery() {
  let interviewSwiper,
    Options,
    pcOptions,
    spOptions,
    setHeight,
    timer,
    responsibleCheck,
    swiperSwitch,
    photoNumber, resNumView;
  responsibleCheck = $('#responsibleCheck').width();
  timer = false;
  swiperSwitch = false;
  photoNumber = $('.slidePhotoBox').length;
  if (photoNumber === 1) {
    Options = {
      loop: false,
      speed: 0,
      slidesPerView: 1,
      centeredSlides: true,
      allowTouchMove: false,
    };
    $('.interviewPrev,.interviewNext').css('display', 'none');
  } else if (photoNumber === 2) {
    Options = {
      loop: false,
      speed: 500,
      slidesPerView: 3,
      centeredSlides: true,
      breakpoints: {
        769: {
          slidesPerView: 1.5,
          spaceBetween: '5%',
          centeredSlides: true,
        },
      },
      navigation: {
        nextEl: '.interviewNext',
        prevEl: '.interviewPrev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    };
  } else {
    Options = {
      loop: true,
      speed: 500,
      slidesPerView: 3,
      centeredSlides: true,
      breakpoints: {
        769: {
          slidesPerView: 1.5,
          spaceBetween: '5%',
          centeredSlides: true,
        },
      },
      navigation: {
        nextEl: '.interviewNext',
        prevEl: '.interviewPrev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    };
  }

  //スワイプ実行処理(PCモード)
  function initAllSwiper() {
    // PC時のみ画像モーダル
    if (responsibleCheck === 1) {
      let addData = {
        init: function () {
          // Swiper内で複製された要素に対して再定義
          modalImgFunc();
        },
      };
      Options.on = addData;
    }

    interviewSwiper = new Swiper('#interviewGallery', Options);
    if (responsibleCheck == 2) {
      setTimeout(function () {
        setHeight = $('.swiper-wrapper').outerHeight(true);
        $('.slidePhotoBox').css('height', setHeight);
      }, 500);
    }
  }
  initAllSwiper();

  //NEXTボタンをクリックしたら移動
  $(document).on('click', '.swiper-slide-next', (e) => {
    $('.interviewNext').trigger("click");
  });

  //PREVボタンをクリックしたら移動
  $(document).on('click', '.swiper-slide-prev', (e) => {
    $('.interviewPrev').trigger("click");
  });

  //リサイズ処理
  $(window).on('resize', function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      //リサイズ終了後の処理
      responsibleCheck = $('#responsibleCheck').width();
      if (responsibleCheck == 1) {
        $('.slidePhotoBox').removeAttr('style');
      } else if (responsibleCheck == 2) {
        setTimeout(function () {
          $('.slidePhotoBox').removeAttr('style');
          setHeight = $('.swiper-wrapper').outerHeight(true);
          $('.slidePhotoBox').css('height', setHeight);
        }, 300);
      }
    }, 200);
  });
}